<template>
    <div>
      <header>
        <h1>北京携济韶华科技有限公司</h1>
        <p>Beijing Xieji Shaohua Technology Co., Ltd.</p>
      </header>
      <div class="container">
        <h2>Welcome to Our Company</h2>
        <p>
          驱动未来，用AI创新生活。我们致力于运用先进的人工智能技术解决人类生活中的挑战，为世界提供更智能、更高效、更人性化的解决方案，塑造一个更加和谐、便捷的未来。
        </p>
        <p>
          Driving the future with AI innovations in everyday life. We are dedicated to harnessing advanced artificial intelligence to address challenges in human living, offering smarter, more efficient, and more user-centric solutions, shaping a more harmonious and convenient future for all.
        </p>
      </div>
      <footer>
        <!-- eslint-disable-next-line -->
        <p>&copy Beijing Xieji Shaohua Technology Co., Ltd., Contact Info: chuaner0502@gmail.com</p>
      </footer>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HomePage'
  };
  </script>
  
  <style>
    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f4f4f4;
    }
  
    header {
      background-color: #50b3a2;
      color: white;
      text-align: center;
      padding: 1em 0;
    }
  
    /* ... 其他样式 ... */
  
    footer {
      margin-top: 1em;
      padding: 1em 0;
      background-color: #333;
      color: white;
      text-align: center;
    }
  </style>
  