import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/components/Home.vue';
import PrivacyPolicy from '@/components/PrivacyPolicy.vue';
import BuddhaAI from '@/components/BuddhaAI.vue';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: Home
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    beforeEnter: (to, from, next) => {
      document.title = '隐私政策';
      next();
    }
  },
  {
    path: '/buddhaAI',
    name: 'BuddhaAI',
    component: BuddhaAI,
    beforeEnter: (to, from, next) => {
      document.title = '佛曰';
      next();
    }
  },
  // 其他路由规则...
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
